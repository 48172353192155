import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { AppContext } from "../../contexts/AppContext"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import { MEDIA_TYPE_AUDIO, DATA_TYPE_STATION } from "../../constants/props"
import { useQuery } from "@apollo/client"
import { QUERY_STATION_BY_ID } from "../../queries/page/station"
import useRecordUserData from "../../hooks/useRecordUserData"
import logoIcon from "../../images/Logo.png"
import loadable from "@loadable/component"

const ReactPlayer = loadable(() => import("../ReactPlayer"))

const ContentEmbededPlayerWraper = styled.div`
  height: 60px;
  width: 99%;
`
const PlayerImage = styled.img`
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 32px;
    position: absolute;
    top: 30px;
    left: 14px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: 32px;
    position: absolute;
    top: 30px;
    left: 14px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: 55px;
    position: absolute;
    top: 30px;
    left: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: 55px;
    position: absolute;
    top: 30px;
    left: 14px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: 55px;
    position: absolute;
    top: 30px;
    left: 14px;
  }
`

const SiteLink = styled.a``

const WebLogo = styled.img`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 40px;
    position: absolute;
    top: 37px;
    right: 10px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: 35px;
    position: absolute;
    top: 30px;
    right: 7px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 45px;
    position: absolute;
    top: 37px;
    right: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 45px;
    position: absolute;
    top: 37px;
    right: 10px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: 60px;
    position: absolute;
    top: 35px;
    right: 20px;
  }
`
const baseAmazonPrefix = `${process.env.AMAZON_BASE_PREFIX}`
const gatsbyServer = `${process.env.GATSBY_SERVER_URL}`

const PlayerContent = ({ station }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, ctxPlayer, userState } = useContext(AppContext)

  useRecordUserData({
    user: userState ? userState.id : null,
    id: station ? station.id : null,
    type: DATA_TYPE_STATION,
  })

  const { id, streamuri, title } = station

  let image =
    station.image && station.image.url
      ? station.image.url.startsWith("http")
        ? station.image.url
        : basePrefix + station.image.url
      : ""

  if (!image || image.trim().length === 0) {
    // show a default station image
    image = baseAmazonPrefix + "/static/default-station-image.jpg"
  }
  useEffect(() => {
    updatePlayer({
      type: MEDIA_TYPE_AUDIO,
      id,
      title,
      streamuri,
      preview: station.image && station.image.url ? station.image.url : "",
      subtitle: station && station.country.title,
      playlist: [],
      dataType: DATA_TYPE_STATION,
      playing: false,
    })
  }, [])

  return (
    <div className="embededPlayer">
      <PlayerImage src={image}></PlayerImage>
      <ReactPlayer></ReactPlayer>
      <SiteLink href={gatsbyServer} target="_blank">
        <WebLogo src={logoIcon}></WebLogo>
      </SiteLink>
    </div>
  )
}

const ContentEmbededPlayer = props => {
  console.log("Content embedded player props is ", props)
  let { data, loading } = useQuery(QUERY_STATION_BY_ID, {
    variables: {
      id: props.stationID,
    },
    fetchPolicy: "network-only",
  })

  return (
    !loading && (
      <ContentEmbededPlayerWraper>
        <PlayerContent station={data.station} />
      </ContentEmbededPlayerWraper>
    )
  )
}

export default ContentEmbededPlayer
